import { FABLETICS_BUILDER_ENUMS } from '~/techstyle-shared/react-builder-models';

export enum ConstructorGridFilterTypes {
  Collection = 'Collection',
  Facet = 'Facet',
  Category = 'Category',
}

export enum ConstructorGridFilterNames {
  Collection = 'collection_id',
  Category = 'group_id',
}

export enum ConstructorGridGender {
  Male = 'M',
  Female = 'F',
}

export const SortOptionsHumanToSnakeMapping = {
  'Most Relevant': 'relevance',
  Newest: 'newest',
  'Top Rated': 'avg_review',
  'Retail Price: High-Low': 'retail_unit_price_h2l',
  'Retail Price: Low-High': 'retail_unit_price_l2h',
  'Default Price: High-Low': 'default_unit_price_h2l',
  'Default Price: Low-High': 'default_unit_price_l2h',
};

const ConstructorGridBuilderComponent = {
  name: '_ConstructorGrid_',
  inputs: [
    {
      name: 'gridHeader',
      friendlyName: 'Grid Header',
      type: 'string',
      required: false,
      helperText:
        'The header text above the grid. If left blank, the grid will not have a header.',
    },
    {
      name: 'gridGender',
      friendlyName: 'Grid Gender',
      type: 'string',
      required: false,
      localized: false,
      enum: Object.values(ConstructorGridGender),
      helperText:
        'The gender for this grid, it is required for automatic size filtering to work.',
    },
    {
      name: 'productSource',
      friendlyName: 'P-Source',
      required: true,
      localized: false,
      helperText:
        'This field sets the P-Source name for tracking revenue on the page.',
      type: 'string',
    },
    {
      name: 'filterType',
      friendlyName: 'Constructor Filter Type',
      type: 'string',
      enum: Object.values(ConstructorGridFilterTypes),
      defaultValue: ConstructorGridFilterTypes.Collection,
      required: true,
      localized: false,
      helperText:
        'The type of filter used to power the constructor grid via the Browse API.',
    },
    {
      name: 'collectionSelection',
      type: 'CollectionSelector', // defined in https://github.com/Fabletics/cf-builderio-constructor-plugin/blob/master/src/plugin.jsx
      friendlyName: 'Constructor Collection Selection',
      helperText:
        'In Constructor, create the Collection of products you want to include in this grid then enter the Collection label here.',
      options: {
        url: 'https://cdn.fabletics.com/media/builder-constructor/plugin.system.js',
      },
      showIf: (options: Map<string, any>) =>
        options.get('filterType') === 'Collection',
      defaultValue: null,
      required: false, // TODO update to true after collectionId is deprecated, see FLD-2118
      localized: false,
    },
    {
      name: 'collectionId',
      friendlyName: '[DEPRECATED] Constructor Collection ID',
      type: 'string',
      defaultValue: null,
      required: false,
      localized: false,
      showIf: (options: Map<string, any>) =>
        options.get('filterType') === 'Collection',
      helperText:
        '[DEPRECATED] please use "Constructor Collection Selection" instead. If the selection field is set this will be ignored. This remains for backwards compatability until all pages are updated to use the selection field.',
    },
    {
      name: 'collectionIdOverride',
      friendlyName: 'Collection ID Override',
      type: 'string',
      defaultValue: null,
      required: false,
      localized: false,
      showIf: (options: Map<string, any>) =>
        options.get('filterType') === 'Collection',
      helperText:
        'Overrides the Constructor Collection Selector in order to use a collection ID that is not available in Constructor.',
      advanced: true,
    },
    {
      name: 'categorySelection',
      friendlyName: 'Constructor Group Selection',
      type: 'CategorySelector', // defined in https://github.com/Fabletics/cf-builderio-constructor-plugin/blob/master/src/plugin.jsx
      defaultValue: null,
      required: false, // TODO update to true after categoryId is deprecated, see FLD-2118
      localized: false,
      showIf: (options: Map<string, any>) =>
        options.get('filterType') === 'Category',
      options: {
        url: 'https://cdn.fabletics.com/media/builder-constructor/plugin.system.js',
      },
      helperText:
        'Select the category for the products you want to include in this grid. You can search by either the label or the ID.',
    },
    {
      name: 'categoryId',
      friendlyName: '[DEPRECATED] Constructor Group ID',
      type: 'string',
      defaultValue: null,
      required: false,
      localized: false,
      showIf: (options: Map<string, any>) =>
        options.get('filterType') === 'Category',
      helperText:
        '[DEPRECATED] please use "Constructor Group Selection" instead. If the selection field is set this field will be ignored. This remains for backwards compatability until all pages are updated to use the selection field.',
    },
    {
      name: 'categoryIdOverride',
      friendlyName: 'Category ID Override',
      type: 'string',
      defaultValue: null,
      required: false,
      localized: false,
      showIf: (options: Map<string, any>) =>
        options.get('filterType') === 'Category',
      helperText:
        'Overrides the Constructor Group Category Selector in order to use a category ID that is not available in Constructor.',
      advanced: true,
    },
    {
      name: 'facet',
      friendlyName: 'Constructor Facet Name',
      type: 'string',
      defaultValue: null,
      required: true,
      localized: false,
      showIf: (options: Map<string, any>) =>
        options.get('filterType') === 'Facet',
      helperText: 'Enter the Elasticsearch field name you want to include.',
    },
    {
      name: 'facetOption',
      friendlyName: 'Constructor Facet Option',
      type: 'string',
      defaultValue: null,
      required: true,
      localized: false,
      showIf: (options: Map<string, any>) =>
        options.get('filterType') === 'Facet',
      helperText:
        'Enter the Constructor Facet Option label you want to include.',
    },
    {
      name: 'assetContainer',
      friendlyName: 'In-Grid Asset Container',
      type: 'object',
      subFields: [
        {
          name: 'gridAssetContainer',
          friendlyName: 'Desktop',
          type: 'string',
          helperText:
            "This container powers the assets inside of the grid on desktop - use the custom vars in CMS to set position and size. Please note that Mobile App only supports the 'position' custom var.",
        },
        {
          name: 'gridAssetContainerMobile',
          friendlyName: 'Mobile',
          type: 'string',
          helperText:
            "This container powers the assets inside of the grid on mobile - use the custom vars in CMS to set position and size. Please note that Mobile App only supports the 'position' custom var.",
        },
      ],
    },
    {
      name: 'sortOptions',
      friendlyName: 'Sort Options',
      type: 'list',
      defaultValue: Object.keys(SortOptionsHumanToSnakeMapping).map((key) => ({
        sortOption: key,
      })),
      helperText:
        'Note: the default price sort options will only show up for VIP users, the retail price sort options will show up for non-VIP users.',
      localized: true,
      subFields: [
        {
          name: 'sortOption',
          type: 'string',
          required: true,
          enum: Object.keys(SortOptionsHumanToSnakeMapping),
        },
      ],
    },
    {
      name: 'sideNav',
      type: 'object',
      advanced: true,
      subFields: [
        {
          name: 'shouldShowCategoryNav',
          helperText:
            'Surfaces the category navigation on both desktop + mobile',
          type: 'boolean',
          defaultValue: false,
        },
        {
          name: 'shouldShowCategoryHeading',
          type: 'boolean',
          defaultValue: false,
        },
        {
          name: 'categoryNavContainer',
          helperText:
            'This is the nav config name inside Nav Tool- the category nav will not show without this field.',
          friendlyName: 'Category Navigationizer ID (Web Only)',
          type: 'string',
        },
      ],
    },
    {
      name: 'gridStyleGuide',
      friendlyName: 'Discovery Guide',
      type: 'object',
      localized: false,
      advanced: true,
      subFields: [
        {
          name: 'tagList',
          friendlyName: 'Filter List',
          type: 'string',
          localized: false,
          enum: FABLETICS_BUILDER_ENUMS.GRID_FILTERS,
          required: true,
        },
        {
          name: 'assetContainers',
          type: 'object',
          localized: false,
          required: true,
          subFields: [
            {
              name: 'desktop',
              type: 'string',
              localized: false,
              required: true,
              helperText:
                'This container powers the assets for discovery guide on desktop. Use the custom var to set the tag name for each asset. Note that container localization is done in CMS.',
            },
            {
              name: 'mobile',
              type: 'string',
              localized: false,
              required: true,
              helperText:
                'This container powers the assets for discovery guide on mobile. Use the custom var to set the tag name for each asset. Note that container localization is done in CMS.',
            },
            {
              name: 'marketingCard',
              type: 'string',
              localized: false,
              required: true,
              helperText:
                'This container powers the marketing card for discovery guide on desktop. Note that container localization is done in CMS.',
            },
          ],
        },
      ],
    },
  ],
} as const;

export const BuilderConstructorGridConfig = {
  ...ConstructorGridBuilderComponent,
};
