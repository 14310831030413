import {
  Builder,
  withChildren as withChildrenWrapper,
} from '@builder.io/react';
import dynamic from 'next/dynamic';

import { PointHistoryBuilderComponentConfig } from '~/account/components/PointHistory/PointHistory.builder';
import { VipSinceConfig } from '~/account/components/VipSince/VipSince.builder';
import { ContactFormConfig } from '~/acquisition/components/ContactForm/ContactForm.builder';
import { RewardPlatinumCountDownConfig } from '~/acquisition/components/RewardPlatinumCountDown/RewardPlatinumCountDown.builder';
import { RewardProgressBarToPlatinumConfig } from '~/acquisition/components/RewardProgressBarToPlatinum/RewardProgressBarToPlatinum.builder';
import { RewardPromoCardsConfig } from '~/acquisition/components/RewardPromoCards/RewardPromoCards.builder';
import { BuilderQuestionConfig } from '~/builderQuiz/components/BuilderQuestion/BuilderQuestion.builder';
import { AccountGreetingConfig } from '~/layout/components/AccountGreeting/AccountGreeting.builder';
import { BuilderMobileAppInfoGridConfig } from '~/mobile-app/components/MobileAppBuilderInfoGrid/MobileAppBuilderInfoGrid.builder';
import { PostregCountdownConfig } from '~/shared/components/PostregCountdown/PostregCountdown.builder';
import { VimeoVideoConfig } from '~/shared/components/Vimeo/VimeoVideo.builder';

import { BuilderMobileAppImageLinkGridConfig } from '../../mobile-app/components/MobileAppBuilderImageLinkGrid/MobileAppBuilderImageLinkGrid.builder';
import { BuilderMobileAppSearchBarConfig } from '../../mobile-app/components/MobileAppBuilderSearchBar/MobileAppBuilderSearchBar.builder';
import { BuilderMobileAppTabSwitcherConfig } from '../../mobile-app/components/MobileAppBuilderTabSwitcher/MobileAppBuilderTabSwitcher.builder';
import { BuilderMobileAppChevronLinkListConfig } from '../../mobile-app/components/MobileAppChevronLinkList/MobileAppChevronLinkList.builder';
import { BuilderAccordionUnitConfig } from '../components/BuilderAccordionUnit/BuilderAccordionUnit.builder';
import { BuilderAssetConfig } from '../components/BuilderAsset/BuilderAsset.builder';
import { BuilderAssetOverlayConfig } from '../components/BuilderAssetOverlay/BuilderAssetOverlay.builder';
import { BuilderAvailablePointsConfig } from '../components/BuilderAvailablePoints/BuilderAvailablePoints.builder';
import { BuilderBubbleImageWithLabelConfig } from '../components/BuilderBubbleImageWithLabel/BuilderBubbleImageWithLabel.builder';
import { BuilderCarouselConfig } from '../components/BuilderCarousel/BuilderCarousel.builder';
import { BuilderCollectionConfig } from '../components/BuilderCollection/BuilderCollection.builder';
import { BuilderComparisonUnitConfig } from '../components/BuilderComparisonUnit/BuilderComparisonUnit.builder';
import { BuilderComparisonUnitItemConfig } from '../components/BuilderComparisonUnitItem/BuilderComparisonUnitItem.builder';
import { BuilderConstructorGridConfig } from '../components/BuilderConstructorGrid/BuilderConstructorGrid.builder';
import { BuilderCtaConfig } from '../components/BuilderCta/BuilderCta.builder';
import { BuilderDividerConfig } from '../components/BuilderDivider/BuilderDivider.builder';
import { BuilderFiftyFiftyImageConfig } from '../components/BuilderFiftyFiftyImage/BuilderFiftyFiftyImage.builder';
import { BuilderFiftyFiftyVideoConfig } from '../components/BuilderFiftyFiftyVideo/BuilderFiftyFiftyVideo.builder';
import { BuilderFindYourFitUnitConfig } from '../components/BuilderFindYourFitUnit/BuilderFindYourFitUnit.builder';
import { BuilderFooterLinkConfig } from '../components/BuilderFooterLink/BuilderFooterLink.builder';
import { BuilderFourColumnGridConfig } from '../components/BuilderFourColumnGrid/BuilderFourColumnGrid.builder';
import { BuilderFullWidthImageAndTextConfig } from '../components/BuilderFullWidthImageAndText/BuilderFullWidthImageAndText.builder';
import { BuilderFullWidthImageWithCaptionConfig } from '../components/BuilderFullWidthImageWithCaption/BuilderFullWidthImageWithCaption.builder';
import { BuilderHeadingConfig } from '../components/BuilderHeading/BuilderHeading.builder';
import { BuilderHeroConfig } from '../components/BuilderHero/BuilderHero.builder';
import { BuilderImageComparisonSliderConfig } from '../components/BuilderImageComparisonSlider/BuilderImageComparisonSlider.builder';
import { BuilderListRowConfig } from '../components/BuilderListRow/BuilderListRow.builder';
import { BuilderLoyaltyActionCtaConfig } from '../components/BuilderLoyaltyActionCta/BuilderLoyaltyActionCta.builder';
import { BuilderLoyaltyActionVideoConfig } from '../components/BuilderLoyaltyActionVideo/BuilderLoyaltyActionVideo.builder';
import { BuilderLoyaltyPointsForActionsConfig } from '../components/BuilderLoyaltyPointsForActions/BuilderLoyaltyPointsForActions.builder';
import { BuilderLoyaltyProductGridConfig } from '../components/BuilderLoyaltyProductGrid/BuilderLoyaltyProductGrid.builder';
import { BuilderMarketingCardsConfig } from '../components/BuilderMarketingCards/BuilderMarketingCards.builder';
import { BuilderMemberDetailTextConfig } from '../components/BuilderMemberDetailText/BuilderMemberDetailText.builder';
import { BuilderMembershipHeaderConfig } from '../components/BuilderMembershipHeader/BuilderMembershipHeader.builder';
import { BuilderModalConfig } from '../components/BuilderModal/BuilderModal.builder';
import { BuilderRichTextConfig } from '../components/BuilderRichText/BuilderRichText.builder';
import { BuilderSendLinkToPhoneNumberConfig } from '../components/BuilderSendLinkToPhoneNumber/BuilderSendLinkToPhoneNumber.builder';
import { BuilderShoppableVideoFeaturedConfig } from '../components/BuilderShoppableVideoFeatured/BuilderShoppableVideoFeatured.builder';
import { BuilderSkipTheMonthCTAConfig } from '../components/BuilderSkipTheMonthCTA/BuilderSkipTheMonthCTA.builder';
import { BuilderStorytellingCTAButtonConfig } from '../components/BuilderStorytellingCTAButton/BuilderStorytellingCTAButton.builder';
import { BuilderStorytellingCTATextConfig } from '../components/BuilderStorytellingCTAText/BuilderStorytellingCTAText.builder';
import { BuilderTabsConfig } from '../components/BuilderTabs/BuilderTabs.builder';
import { BuilderFableticsTargetedSectionBuilderComponentConfig } from '../components/BuilderTargetedSection/BuilderTargetedSection.builder';
import { BuilderTestimonialsConfig } from '../components/BuilderTestimonials/BuilderTestimonials.builder';
import { BuilderTextConfig } from '../components/BuilderText/BuilderText.builder';
import { BuilderTwoColumnGridConfig } from '../components/BuilderTwoColumnGrid/BuilderTwoColumnGrid.builder';
import { BuilderUgcConfig } from '../components/BuilderUgc/BuilderUgc.builder';
import { BuilderVideoCarouselConfig } from '../components/BuilderVideoCarousel/BuilderVideoCarousel.builder';
import { BuilderLoyaltyRewardProgressConfig } from '../components/deprecated/BuilderLoyaltyRewardProgress/BuilderLoyaltyRewardProgress.builder';
import { BuilderLoyaltyVideoConfig } from '../components/deprecated/BuilderLoyaltyVideo/BuilderLoyaltyVideo.builder';
import { BuilderAccountNavLinkConfig } from '../components/header/BuilderAccountNavLink/BuilderAccountNavLink.builder';
import { BuilderCountryDropdownConfig } from '../components/header/BuilderCountryDropdown/BuilderCountryDropdown.builder';
import { BuilderExpansionPanelConfig } from '../components/header/BuilderExpansionPanel/BuilderExpansionPanel.builder';
import { BuilderHamburgerMenuConfig } from '../components/header/BuilderHamburgerMenu/BuilderHamburgerMenu.builder';
import { BuilderHeaderModelContainerConfig } from '../components/header/BuilderHeaderModelContainer/BuilderHeaderModelContainer.builder';
import { BuilderListColumnConfig } from '../components/header/BuilderListColumn/BuilderListColumn.builder';
import { BuilderLogoConfig } from '../components/header/BuilderLogo/BuilderLogo.builder';
import { BuilderMemberInfoConfig } from '../components/header/BuilderMemberInfo/BuilderMemberInfo.builder';
import { BuilderMetaNavConfig } from '../components/header/BuilderMetaNav/BuilderMetaNav.builder';
import { BuilderMiniCartConfig } from '../components/header/BuilderMiniCart/BuilderMiniCart.builder';
import { BuilderMyAccountFlyoutMenuConfig } from '../components/header/BuilderMyAccountFlyoutMenu/BuilderMyAccountFlyoutMenu.builder';
import { BuilderNavLinkConfig } from '../components/header/BuilderNavLink/BuilderNavLink.builder';
import { BuilderNavTabsListWithContentConfig } from '../components/header/BuilderNavTabsListWithContent/BuilderNavTabsListWithContent.builder';
import { BuilderNavTabsListWithFlyoutMenuConfig } from '../components/header/BuilderNavTabsListWithFlyoutMenu/BuilderNavTabsListWithFlyoutMenu.builder';
import { BuilderSearchBarConfig } from '../components/header/BuilderSearchBar/BuilderSearchBar.builder';
import { BuilderSearchIconConfig } from '../components/header/BuilderSearchIcon/BuilderSearchIcon.builder';
import { BuilderSignInWithModalConfig } from '../components/header/BuilderSignInWithModal/BuilderSignInWithModal.builder';
import { BuilderStickyHeaderContainerConfig } from '../components/header/BuilderStickyHeaderContainer/BuilderStickyHeaderContainer.builder';
import { BuilderTertiaryNavItemConfig } from '../components/header/BuilderTertiaryNavItem/BuilderTertiaryNavItem.builder';
import { BuilderVideoShoppingFeatureConfig } from '../components/header/BuilderVideoShoppingFeature/BuilderVideoShoppingFeature.builder';

export const quizComponents = [
  [
    dynamic(() => import('~/builderQuiz/components/BuilderQuestion')),
    BuilderQuestionConfig,
  ],
];

export const webOnlyComponents = [
  [dynamic(() => import('~/shared/components/Vimeo')), VimeoVideoConfig],
  [
    dynamic(() => import('~/builder/components/BuilderFooterLink')),
    BuilderFooterLinkConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderListRow')),
    BuilderListRowConfig,
  ],
];

export const navWebComponents = [
  [
    dynamic(() => import('~/builder/components/header/BuilderAccountNavLink')),
    BuilderAccountNavLinkConfig,
  ],
  [
    dynamic(() => import('~/builder/components/header/BuilderCountryDropdown')),
    BuilderCountryDropdownConfig,
  ],
  [
    dynamic(() => import('~/builder/components/header/BuilderExpansionPanel')),
    BuilderExpansionPanelConfig,
  ],
  [
    dynamic(() => import('~/builder/components/header/BuilderHamburgerMenu')),
    BuilderHamburgerMenuConfig,
  ],
  [
    dynamic(() =>
      import('~/builder/components/header/BuilderHeaderModelContainer')
    ),
    BuilderHeaderModelContainerConfig,
  ],
  [
    dynamic(() => import('~/builder/components/header/BuilderListColumn')),
    BuilderListColumnConfig,
  ],
  [
    dynamic(() => import('~/builder/components/header/BuilderLogo')),
    BuilderLogoConfig,
  ],
  [
    dynamic(() => import('~/builder/components/header/BuilderMemberInfo')),
    BuilderMemberInfoConfig,
  ],
  [
    dynamic(() => import('~/builder/components/header/BuilderMetaNav')),
    BuilderMetaNavConfig,
  ],
  [
    dynamic(() => import('~/builder/components/header/BuilderMiniCart')),
    BuilderMiniCartConfig,
  ],
  [
    dynamic(() =>
      import('~/builder/components/header/BuilderMyAccountFlyoutMenu')
    ),
    BuilderMyAccountFlyoutMenuConfig,
  ],
  [
    dynamic(() => import('~/builder/components/header/BuilderNavLink')),
    BuilderNavLinkConfig,
  ],
  [
    dynamic(() =>
      import('~/builder/components/header/BuilderNavTabsListWithContent')
    ),
    BuilderNavTabsListWithContentConfig,
  ],
  [
    dynamic(() =>
      import('~/builder/components/header/BuilderNavTabsListWithFlyoutMenu')
    ),
    BuilderNavTabsListWithFlyoutMenuConfig,
  ],
  [
    dynamic(() => import('~/builder/components/header/BuilderSearchBar')),
    BuilderSearchBarConfig,
  ],
  [
    dynamic(() => import('~/builder/components/header/BuilderSearchIcon')),
    BuilderSearchIconConfig,
  ],
  [
    dynamic(() => import('~/builder/components/header/BuilderSignInWithModal')),
    BuilderSignInWithModalConfig,
  ],
  [
    dynamic(() =>
      import('~/builder/components/header/BuilderStickyHeaderContainer')
    ),
    BuilderStickyHeaderContainerConfig,
  ],
  [
    dynamic(() => import('~/builder/components/header/BuilderTertiaryNavItem')),
    BuilderTertiaryNavItemConfig,
  ],
  [
    dynamic(() =>
      import('~/builder/components/header/BuilderVideoShoppingFeature')
    ),
    BuilderVideoShoppingFeatureConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderTargetedSection')),
    BuilderFableticsTargetedSectionBuilderComponentConfig,
  ],
];

export const pdpComponents = [
  [
    dynamic(() => import('~/builder/components/BuilderAccordionUnit')),
    BuilderAccordionUnitConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderCarousel')),
    BuilderCarouselConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderComparisonUnit')),
    BuilderComparisonUnitConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderComparisonUnitItem')),
    BuilderComparisonUnitItemConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderDivider')),
    BuilderDividerConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderFindYourFitUnit')),
    BuilderFindYourFitUnitConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderFiftyFiftyImage')),
    BuilderFiftyFiftyImageConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderFiftyFiftyVideo')),
    BuilderFiftyFiftyVideoConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderFourColumnGrid')),
    BuilderFourColumnGridConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderFullWidthImageAndText')),
    BuilderFullWidthImageAndTextConfig,
  ],
  [
    dynamic(() =>
      import('~/builder/components/BuilderFullWidthImageWithCaption')
    ),
    BuilderFullWidthImageWithCaptionConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderHeading')),
    BuilderHeadingConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderImageComparisonSlider')),
    BuilderImageComparisonSliderConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderModal')),
    BuilderModalConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderTestimonials')),
    BuilderTestimonialsConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderTwoColumnGrid')),
    BuilderTwoColumnGridConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderStorytellingCTAButton')),
    BuilderStorytellingCTAButtonConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderStorytellingCTAText')),
    BuilderStorytellingCTATextConfig,
  ],
  [
    dynamic(() => import('~/layout/components/AccountGreeting')),
    AccountGreetingConfig,
  ],
];

const mobileAppComponents = [
  [
    dynamic(() => import('~/mobile-app/components/MobileAppBuilderSearchBar')),
    BuilderMobileAppSearchBarConfig,
  ],
  [
    dynamic(() =>
      import('~/mobile-app/components/MobileAppBuilderImageLinkGrid')
    ),
    BuilderMobileAppImageLinkGridConfig,
  ],
  [
    dynamic(() =>
      import('~/mobile-app/components/MobileAppBuilderTabSwitcher')
    ),
    BuilderMobileAppTabSwitcherConfig,
  ],
  [
    dynamic(() =>
      import(
        '~/mobile-app/components/MobileAppChevronLinkList/MobileAppChevronLinkList'
      )
    ),
    BuilderMobileAppChevronLinkListConfig,
  ],
  [
    dynamic(() => import('~/mobile-app/components/MobileAppBuilderInfoGrid')),
    BuilderMobileAppInfoGridConfig,
  ],
];

export const allComponents = [
  [
    dynamic(() => import('~/builder/components/BuilderAsset')),
    BuilderAssetConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderBubbleImageWithLabel')),
    BuilderBubbleImageWithLabelConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderCollection')),
    BuilderCollectionConfig,
  ],
  [dynamic(() => import('~/builder/components/BuilderCta')), BuilderCtaConfig],
  [
    dynamic(() => import('~/builder/components/BuilderHero')),
    BuilderHeroConfig,
  ],
  [
    dynamic(() => import('~/acquisition/components/RewardPromoCards')),
    RewardPromoCardsConfig,
  ],
  [
    dynamic(() => import('~/acquisition/components/RewardPlatinumCountDown')),
    RewardPlatinumCountDownConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderLoyaltyActionCta')),
    BuilderLoyaltyActionCtaConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderLoyaltyActionVideo')),
    BuilderLoyaltyActionVideoConfig,
  ],
  [
    dynamic(() =>
      import('~/builder/components/BuilderLoyaltyPointsForActions')
    ),
    BuilderLoyaltyPointsForActionsConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderLoyaltyProductGrid')),
    BuilderLoyaltyProductGridConfig,
  ],
  [
    dynamic(() =>
      import('~/builder/components/deprecated/BuilderLoyaltyRewardProgress')
    ),
    BuilderLoyaltyRewardProgressConfig,
  ],
  [
    dynamic(() =>
      import('~/builder/components/deprecated/BuilderLoyaltyVideo')
    ),
    BuilderLoyaltyVideoConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderSendLinkToPhoneNumber')),
    BuilderSendLinkToPhoneNumberConfig,
  ],
  [
    dynamic(() =>
      import('~/acquisition/components/RewardProgressBarToPlatinum')
    ),
    RewardProgressBarToPlatinumConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderMarketingCards')),
    BuilderMarketingCardsConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderMembershipHeader')),
    BuilderMembershipHeaderConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderTabs')),
    BuilderTabsConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderText')),
    BuilderTextConfig,
  ],
  [dynamic(() => import('~/builder/components/BuilderUgc')), BuilderUgcConfig],
  [
    dynamic(() => import('~/acquisition/components/ContactForm')),
    ContactFormConfig,
  ],
  [dynamic(() => import('~/account/components/VipSince')), VipSinceConfig],
  [
    dynamic(() => import('~/shared/components/PostregCountdown')),
    PostregCountdownConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderAssetOverlay')),
    BuilderAssetOverlayConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderAvailablePoints')),
    BuilderAvailablePointsConfig,
  ],
  [
    dynamic(() => import('~/account/components/PointHistory')),
    PointHistoryBuilderComponentConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderSkipTheMonthCTA')),
    BuilderSkipTheMonthCTAConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderShoppableVideoFeatured')),
    BuilderShoppableVideoFeaturedConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderRichText')),
    BuilderRichTextConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderVideoCarousel')),
    BuilderVideoCarouselConfig,
  ],
  [
    dynamic(() => import('~/builder/components/BuilderMemberDetailText')),
    BuilderMemberDetailTextConfig,
  ],

  ...pdpComponents,
  ...webOnlyComponents,
  ...quizComponents,
  ...navWebComponents,
  ...mobileAppComponents,
];

// The BuilderConstructorGrid is still under development and meant to only be supported for the WIP CollectionPage and CategoryPage.
// Please do not include the following in the allComponents array to prevent it from being available for other page types in the Builder UI.
// Ticket to explore best way to handle when nearing feature complete:
// https://techstyle-prod.atlassian.net/browse/FLD-2035
export const gridOnlyComponents = [
  [
    dynamic(() => import('~/builder/components/BuilderCollection')),
    { ...BuilderCollectionConfig },
  ],
  [
    dynamic(() => import('~/builder/components/BuilderConstructorGrid')),
    { ...BuilderConstructorGridConfig },
  ],
];

export const getComponentNamesForMenu = (components = []) => {
  return components.map(([importedComponent, builderConfig]) => ({
    name: builderConfig?.name,
  }));
};

export function registerBuilderComponents(components = []) {
  components.forEach((component) => {
    if (Array.isArray(component)) {
      const [importedComponent, builderConfig] = component;
      const { canHaveChildren, ...builderProps } = builderConfig;
      Builder.registerComponent(
        canHaveChildren
          ? withChildrenWrapper(importedComponent)
          : importedComponent,
        builderProps
      );
    } else {
      const { withChildren, ...builderProps } = component.builderComponent;
      Builder.registerComponent(
        withChildren ? withChildrenWrapper(component) : component,
        builderProps
      );
    }
  });
}

export function registerBuilderMenu() {
  Builder.register('insertMenu', {
    name: 'FL Components',
    items: [
      { name: '_Asset_' },
      { name: '_Collection_' },
      { name: '_ContactForm_' },
      { name: '_Cta_' },
      { name: '_Hero_' },
      { name: '_MarketingCards_' },
      { name: '_Tabs_' },
      { name: '_Text_' },
      { name: '_Ugc_' },
      { name: '_VideoCarousel_' },
      { name: '_LoyaltyVideo_' },
      { name: '_SendLinkToPhoneNumber_' },
      { name: '_MemberDetailText_' },
    ],
  });

  Builder.register('insertMenu', {
    name: 'FL Components - Web Only',
    items: [
      { name: '_BubbleImageWithLabel_' },
      { name: '_PostregCountdownTimer_' },
      { name: '_BuilderAssetOverlay_' },
      { name: '_Tabs_' },
      { name: '_Vimeo_' },
      { name: '_RichText_' },
      { name: '_ShoppableVideoFeatured_' },
    ],
  });

  Builder.register('insertMenu', {
    name: 'FL Components - Footer (Web Only)',
    items: [
      { name: '_FooterLink_' },
      { name: '_ExpansionPanel_' },
      { name: '_ListColumn_' },
      { name: '_ListRow_' },
      { name: '_TargetedSection_' },
    ],
  });

  Builder.register('insertMenu', {
    name: 'FL Components - Header (Web Only)',
    items: [
      { name: '_AccountNavLink_' },
      { name: '_CountryDropdown_' },
      { name: '_ExpansionPanel_' },
      { name: '_HamburgerMenu_' },
      { name: '_HeaderModelContainer_' },
      { name: '_ListColumn_' },
      { name: '_Logo_' },
      { name: '_MemberInfo_' },
      { name: '_MetaNav_' },
      { name: '_MetanavBrandList_' },
      { name: '_MiniCart_' },
      { name: '_MyAccountFlyoutMenu_' },
      { name: '_NavLink_' },
      { name: '_NavTabsListWithContent_' },
      { name: '_NavTabsListWithFlyoutMenu_' },
      { name: '_SearchBar_' },
      { name: '_SearchIcon_' },
      { name: '_SignInWithModal_' },
      { name: '_StickyHeaderContainer_' },
      { name: '_Stores_' },
      { name: '_TargetedSection_' },
      { name: '_TermsModal_' },
      { name: '_TertiaryNavItem_' },
      { name: '_VideoShopping_' },
      { name: '_VideoShoppingFeature_' },
    ],
  });

  Builder.register('insertMenu', {
    name: 'FL Components - Loyalty',
    items: [
      { name: '_LoyaltyActionCta_' },
      { name: '_LoyaltyActionVideo_' },
      { name: '_LoyaltyPointsForActions_' },
    ],
  });

  Builder.register('insertMenu', {
    name: 'FL Components - Storytelling',
    items: [
      { name: '_CTAButton_' },
      { name: '_CTAText_' },
      { name: '_Heading_' },
      { name: '_Divider_' },
      { name: '_2ColumnGrid_' },
      { name: '_4ColumnGrid_' },
      { name: '_FiftyFiftyImage_' },
      { name: '_FiftyFiftyVideo_' },
      { name: '_FullWidthImageAndText_' },
      { name: '_FullWidthImageWithCaption_' },
      { name: '_AccordionUnit_' },
      { name: '_Carousel_' },
      { name: '_Testimonials_' },
      { name: '_ImageComparisonSlider_' },
      { name: '_ComparisonUnit_' },
      { name: '_FindYourFitUnit_' },
      { name: '_Modal_' },
    ],
  });

  Builder.register('insertMenu', {
    name: 'FL Components - My Account',
    items: [
      { name: '_AccountGreeting_' },
      { name: '_AvailablePoints_' },
      { name: '_LoyaltyCashRewardGrid_' },
      { name: '_LoyaltyPlatinumCountdown_' },
      { name: '_LoyaltyProductGrid_' },
      { name: '_LoyaltyProgressBarToPlatinum_' },
      { name: '_MembershipHeaderGreetingAndBadge_' },
      { name: '_VipSince_' },
      { name: '_PointHistory_' },
      { name: '_SkipTheMonthCTA_' },
      { name: '_LoyaltyRewardProgress_' },
    ],
  });

  Builder.register('insertMenu', {
    name: 'FL Components - Quiz',
    items: [{ name: '_Question_' }],
  });

  Builder.register('insertMenu', {
    name: 'FL Components - Mobile App Only',
    items: [
      { name: 'MobileAppSearchBar' },
      { name: 'MobileAppImageLinkGrid' },
      { name: 'MobileAppTabSwitcher' },
      { name: 'MobileAppChevronLinkList' },
      { name: 'MobileAppInfoGrid' },
    ],
  });
}
