const SendLinkToPhoneNumberBuilderComponent = {
  name: '_SendLinkToPhoneNumber_',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F81a15681c3e74df09677dfc57a615b13',
  noWrap: true,
  inputs: [
    {
      name: 'placeholderText',
      friendlyName: 'Placeholder text for input field',
      type: 'string',
      defaultValue: 'Phone Number',
      localized: true,
      required: true,
    },
    {
      name: 'ctaText',
      friendlyName: 'Text content for the CTA button',
      type: 'string',
      defaultValue: 'Click me!',
      localized: true,
      required: true,
    },
  ],
} as const;

export const BuilderSendLinkToPhoneNumberConfig = {
  ...SendLinkToPhoneNumberBuilderComponent,
};
